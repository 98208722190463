import React from 'react';
// import { useStaticQuery, graphql } from 'gatsby';

// components
import Layout from '@src/layouts';
import Link from '@components/atoms/Link';
// import ButtonPrimary from '@components/atoms/buttons/ButtonPrimary';
import SectionTitle from '@components/atoms/SectionTitle';
// import Cta from '@components/molecules/Cta';
import HeroJob from '@components/molecules/heros/HeroJob';
// import ListCards from '@components/organisms/ListCards';
// import ListTeam from '@components/organisms/ListTeam';

// assets
// import imgBrandingStrategy from '@assets/images/ico-branding-brand-positioning.svg';

// --------------------------------
// #region data
// --------------------------------

const language = 'en';

const pageClass = 'page-job';

const footerRelatedLinks = [
	{ title: 'Team', url: '/en/team/' },
	{ title: 'Culture', url: '/en/culture/' },
];

// const cardsList = [
// 	{
// 		image: imgBrandingStrategy,
// 		title: 'Positionnement',
// 		text:
// 			"Positionnement et définition de la personnalité d'une marque par l'intermédiaire de workshops.",
// 	},
// 	{
// 		image: imgBrandingStrategy,
// 		title: 'Création de logo',
// 		text:
// 			"Conception et dessin d'un sigle permettant d'être reconnu au sein de votre marché.",
// 	},
// 	{
// 		image: imgBrandingStrategy,
// 		title: 'Motion Design',
// 		text:
// 			"Création d'animations et de micro-interactions pour les supports digitaux de votre marque.",
// 	},
// 	{
// 		image: imgBrandingStrategy,
// 		title: 'Identité visuelle',
// 		text:
// 			'Création des éléments graphiques et de supports permettant à votre marque de se rendre visible.',
// 	},
// 	{
// 		image: imgBrandingStrategy,
// 		title: 'Illustration',
// 		text:
// 			"Réalisation d'un univers visuel propre à votre marque. De l'illustration à la définition d'une iconographie en adéquation avec vos besoins.",
// 	},
// 	{
// 		image: imgBrandingStrategy,
// 		title: 'UI – User Interface',
// 		text:
// 			"Création de l’interface  digitale où l'animation, l'illustration, la typographie et autres éléments visuels composent un ensemble cohérent.",
// 	},
// ];

// --------------------------------
// #endregion
// --------------------------------

const JobDetailPage = (props) => {
	// const data = useStaticQuery(
	// 	graphql`
	// 		query {
	// 			teamImages: allFile(
	// 				filter: {
	// 					relativePath: {
	// 						in: [
	// 							"team/angela.jpg"
	// 							"team/hugo.jpg"
	// 							"team/luis.jpg"
	// 							"team/felipe.jpg"
	// 						]
	// 					}
	// 				}
	// 			) {
	// 				edges {
	// 					node {
	// 						relativePath
	// 						name
	// 						childImageSharp {
	// 							id
	// 							fluid(
	// 								maxWidth: 228
	// 								quality: 90
	// 								traceSVG: {
	// 									color: "#1f1f1f"
	// 									background: "#dddddd"
	// 								}
	// 							) {
	// 								...GatsbyImageSharpFluid_withWebp_noBase64
	// 							}
	// 						}
	// 					}
	// 				}
	// 			}
	// 		}
	// 	`
	// );

	// data.getImageAttributes = function (dataKey, relativePath) {
	// 	const imageAttributes = this[dataKey]['edges'].find(
	// 		({ node }) => node.relativePath === relativePath
	// 	).node.childImageSharp;

	// 	return imageAttributes;
	// };

	// const teamMembers = [
	// 	{
	// 		name: 'Ângela',
	// 		role: 'Frontend Developer',
	// 		image: data.getImageAttributes('teamImages', 'team/angela.jpg'),
	// 		link: '/todo',
	// 	},
	// 	{
	// 		name: 'Luis',
	// 		role: 'Frontend Developer',
	// 		image: data.getImageAttributes('teamImages', 'team/luis.jpg'),
	// 		link: '/todo',
	// 	},
	// 	{
	// 		name: 'Hugo',
	// 		role: 'Partner, Fullstack Developer',
	// 		image: data.getImageAttributes('teamImages', 'team/hugo.jpg'),
	// 		link: '/todo',
	// 	},
	// 	{
	// 		name: 'Felipe',
	// 		role: 'Fullstack Developer',
	// 		image: data.getImageAttributes('teamImages', 'team/felipe.jpg'),
	// 		link: '/todo',
	// 	},
	// ];

	return (
		<Layout
			language={language}
			pageClass={pageClass}
			footerRelatedLinks={footerRelatedLinks}
			location={props.location}
			translations={[{ uri: '/fr/rejoindre/tech-lead/', lang: 'fr' }]}
			title="Rejoins superhuit en tant que Tech Lead à Lausanne"
			seo={{
				description: `Représenter la compétence technique de l’agence, à l’interne comme à l’externe, et porter son évolution sur le plan technique.`,
			}}
		>
			<HeroJob
				breadcrumb={{ title: 'Open positions', link: '/en/jobs/' }}
				title="Tech Lead"
				location="Lausanne, Switzerland"
				anchors={[
					// {
					// 	title: 'In Short',
					// 	href: '#brief',
					// },
					{
						title: 'You are',
						href: '#you-are',
					},
					{
						title: 'We are',
						href: '#we-are',
					},
				]}
			/>
			<section
				className="grid text-content text-center"
				aria-label="Introduction"
			>
				<h2 data-animation-page className="col-big">
					Représenter la compétence technique de l’agence, à l’interne
					comme à l’externe, et porter son évolution sur le plan
					technique.
				</h2>
			</section>

			{/* <section
				id="brief"
				className="grid grid-content"
				aria-label="Brief"
				data-animation-page
			>
				<ListCards modifiers="zigzag" cardsList={cardsList} />
			</section> */}

			<section
				id="you-are"
				className="grid grid-content"
				aria-label="Skills &amp; Personality"
			>
				<div className="box text-content" data-animation-page>
					<p className="uptitle">Skills &amp; Personality</p>
					<div className="list-two-columns">
						<div className="text-content">
							<h3>You are</h3>
							<p>
								We are looking for someone who is passionate
								about web and innovation and who would like to
								advance the agency's technical expertise and
								digital offerings, in collaboration with our
								(golden) team including 4 senior developers.
							</p>
							<p>
								Strong technical competence to be able to
								provide strategic support to the team on complex
								topics and represent the agency's credibility to
								prospects and clients.
							</p>
							<p>
								Places great importance on quality - a major
								objective of ours is to be increasingly proud of
								our creations.
							</p>
							<p>
								Interest in branding, business, strategy, and
								innovation.
							</p>
							<h3>Perspective</h3>
							<ul className="list-default">
								<li>
									Be fully part of the development team and
									involved in projects, while ensuring the
									technical direction of the agency.
								</li>
								<li>
									Support the dev team in its evolution and
									ensure the relevance of technical solutions
									(quality, efficiency, technical choices).
								</li>
								<li>
									Work closely on the agency's growth strategy
									with partners: recruitment, business
									development, dev profitability.
								</li>
								<li>
									Represent superhuit in a reliable, credible
									and ambitious way to clients and the team
									(partners, client partners, designers,
									developers), including in RFPs and pitches.
								</li>
								<li>
									Identify innovation and differentiation
									opportunities for the company and
									collaborate with the senior dev team to push
									the quality and efficiency of our creations.
								</li>
							</ul>
						</div>
						<div className="text-content">
							<h3>Required Skills</h3>
							<ul className="list-default">
								<li>
									At least 3 years as a senior developer
									and/or tech lead in a web agency
								</li>
								<li>
									Experience in JavaScript/Node.js,
									React/Next.js, PHP/WordPress is a big plus
								</li>
								<li>
									Excellent strategic understanding of how the
									web works and its challenges (hosting,
									performance, SEO, technological choices, DX,
									security,...)
								</li>
								<li>
									Ability to analyze technical feasibility of
									a project, define a vision and estimate
									implementation
								</li>
								<li>
									Excellent interpersonal and communication
									skills
								</li>
								<li>
									Fluent in both French and English (written
									and verbal communication)
								</li>
							</ul>
						</div>
					</div>
				</div>
			</section>

			<section id="we-are" aria-label="Superhuit">
				<SectionTitle title="Let's work together" />

				<div className="grid">
					<div className="box text-content" data-animation-page>
						<p className="uptitle">Superhuit</p>
						<h2>Let's work together</h2>
						<div className="list-two-columns">
							<div className="text-content">
								<h4>We are</h4>
								<p>
									An agency recognized for creating measurable
									strategies, powerful brands, ambitious
									designs and engaging web solutions.
								</p>
								<p>
									Collaboration is at the heart of our
									user-centered approach, and we support our
									clients at every step of their
									transformation. We are constantly looking
									for innovative and varied projects that our
									passionate team loves to work on.
								</p>
								<p>
									While experience is fundamental in
									recruitment, we place great value on
									curiosity and willingness to learn. Our goal
									is to grow while maintaining a human-sized
									team.
								</p>
							</div>
							<div className="text-content">
								<h4>We offer</h4>
								<ul className="list-default">
									<li>
										A fundamentally positive, curious,
										caring and trusting team culture. Open
										to exploring new grounds.
									</li>
									<li>
										Close strategic collaboration with
										partners and team to shape the agency's
										future.
									</li>
									<li>
										We place great importance on regular
										feedback, we try new ways of doing and
										thinking, we support skill development
										through training, sharing sessions, and
										individual and team coaching. The
										company is a ground for personal growth,
										it serves humans and not the other way
										around.
									</li>
									<li>
										Flexibility in work time management,
										work rate 80-100% and remote work is
										common in the team (flexible but minimum
										2 days/week at the agency, to learn
										together and maintain strong team
										spirit).
									</li>
									<li>
										Everyone contributes to the agency's
										development, profits are shared within
										the team.
									</li>
									<li>
										Varied and quality projects for renowned
										local and international clients:
										Experience or corporate sites (Next.js,
										Headless WordPress), Web products
										(recent example: UX, UI and frontend
										redesign of an ERP in React), Headless
										e-commerce, AI integration — recent
										example: RAG solution
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>

				{/* <Cta
					text="Want to join us in Porto?"
					cta="Apply now"
					url="/todo"
				/> */}
			</section>

			<section aria-label="Apply" className="grid" id="apply">
				<div
					className="text-center box box--no-border text-content"
					data-animation-page
				>
					<h2>Apply</h2>
					<p>
						<span
							style={{ display: 'inline-block', maxWidth: 500 }}
						>
							Drop us an email at{' '}
							<Link
								href="mailto:job-dev@superhuit.ch"
								style={{ whiteSpace: 'pre' }}
							>
								job-dev@superhuit.ch
							</Link>{' '}
							and show us the projects you are most proud of.
						</span>
					</p>
					{/* <p>
						<span
							style={{ display: 'inline-block', maxWidth: 500 }}
						>
							Dis-nous également ce que tu attends de ce poste,
							une compétence que tu maîtrises, une compétence que
							tu souhaites développer et ce que tu penses de
							l'état actuel du web
						</span>
					</p> */}
				</div>
			</section>
		</Layout>
	);
};

export default JobDetailPage;
